<template>
  <div class="page-index-wide">
    <div class="head">
      <div v-show="active !== 2" class="butClass" @click="returnClick()">返回</div>
    </div>
    <el-row :gutter="20" class="divClass">
      <el-col :span="8" :offset="8">
        <el-steps :active="active" finish-status="success">
          <el-step title="验证身份" />
          <el-step title="重置密码" />
          <el-step title="完成" />
        </el-steps>
        <!-- 手机号验证 -->
        <div v-if="active === 0">
          <div class="inputPhone">
            <el-input v-model="PhoneValue" placeholder="请输入你的手机号" />
            <span class="TipsClass" v-if="errors.Phone">{{
              errors.Phone
            }}</span>
          </div>
          <div class="inputPhone01">
            <el-input v-model="verifyCode" placeholder="请输入验证码" />
            <el-button
              style="margin-left: 18px"
              :disabled="disabled"
              @click="getCodeClick()"
            >
              {{ btnTitle }}</el-button
            >
          </div>
        </div>
        <!-- 新密码设置 -->
        <div v-if="active === 1">
          <div class="inputPhone">
            <el-input
              type="password"
              v-model="NewPassword"
              autocomplete="off"
              show-password
              placeholder="请设置你的新密码"
            >
            </el-input>
          </div>
          <div class="inputPhone">
            <el-input
              type="password"
              v-model="ConfNewPassword"
              autocomplete="off"
              show-password
              placeholder="请确定你的新密码"
            />
            <span class="TipsClass" v-if="Passwords.ConfNewPassword">
              {{ Passwords.ConfNewPassword }}</span
            >
          </div>
        </div>
        <!-- 完成 -->
        <div v-if="active === 2">
          <div class="inputPhone">
            <img
              style="margin: 0px 0px 20px 98px"
              src="@/assets/login/success.png"
            />
            <div class="fonttxe">
              &nbsp;&nbsp;&nbsp;&nbsp;点击完成新密码设置成功！左上角返回后从新登录。
            </div>
          </div>
        </div>
        <div>
          <div v-if="active !== 2" class="nextClass" @click="next()">
            下一步
          </div>
          <div v-else class="nextClass" @click="completeClick()">完成</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
  onUnmounted,
} from "vue";
import {
  selcetPhoneApi,
  getPhoneCodeApi,
  updatePasswordApi,
} from "@/api/forgetPassword/forgetPassword";
import { requestApi } from "@/utils/requestApi";
import encryptUtil from "@/utils/encryptUtil";

export default {
  name: "ForgetPassword",
  props: {
    msg: String,
  },
  setup() {
    const active = ref(0);
    const next = () => {
      // if (active.value++ > 2) active.value = 0;
      if (data.PhoneValue && data.verifyCode && data.verifyCode === data.PhoneCode) {
        active.value = 1;
        if (data.NewPassword) {
          methods.validatePassword();
        } else {
          proxy.$notify({
            title: "失败",
            message: "请设置新密码",
            type: "error",
          });
        }
      } else {
        proxy.$notify({
          title: "失败",
          message: "验证码错误或手机号错误",
          type: "error",
        });
      }
    };
    const { proxy } = getCurrentInstance();
    const data = reactive({
      companyId: "37", // 本地企业id：37 线上企业id：33
      PhoneValue: "", // 输入手机号
      verifyCode: "", //验证码
      btnTitle: "获取验证码",
      disabled: false, //是否可点击
      errors: {}, //验证提示信息
      PhoneCode: "", //手机验证码
      userId: null, // 用户id
      NewPassword: "", // 新密码
      ConfNewPassword: "", // 确定新密码
      Passwords: {}, // 密码验证
    });
    onMounted(() => {});
    onUnmounted(() => {
      clearInterval(proxy.codeInterval);
    });
    const router = useRouter();

    const methods = {
      // 返回登录
      returnClick() {
        router.push({ path: "/login" });
      },

      // 点击获取验证码 查询手机号
      async getCodeClick() {
        if (proxy.validatePhone()) {
          // 如输入手机号正确并查找手机号成功
          await requestApi(
            () => {
              return selcetPhoneApi({
                companyId: data.companyId,
                phone: data.PhoneValue,
              });
            },
            (res) => {
              if (res.code == 200) {
                data.userId = res.data.userId;
                methods.getPhoneCodeClick();
                methods.validateBtn();
              }
            }
          );
        }
      },
      validatePhone() {
        //判断输入的手机号是否合法
        if (!data.PhoneValue) {
          data.errors = {
            Phone: "手机号码不能为空...",
          };
          // return false
        } else if (!/^1[345678]\d{9}$/.test(data.PhoneValue)) {
          data.errors = {
            Phone: "请输入正确是手机号...",
          };
          // return false
        } else {
          data.errors = {};
          return true;
        }
      },
      validatePassword() {
        //判断密码是否一致
        if (!data.ConfNewPassword) {
          data.Passwords = {
            ConfNewPassword: "确认密码不能为空...",
          };
          // return false
        } else if (
          data.ConfNewPassword &&
          data.NewPassword !== data.ConfNewPassword
        ) {
          data.Passwords = {
            ConfNewPassword: "密码不一致...",
          };
          // return false
        } else {
          methods.updatePasswordClick();
          data.errors = {};
          return true;
        }
      },
      validateBtn() {
        //倒计时
        let time = 60;
        let timer = setInterval(() => {
          if (time == 0) {
            clearInterval(timer);
            data.disabled = false;
            data.btnTitle = "获取验证码";
          } else {
            data.btnTitle = time + "秒后重试";
            data.disabled = true;
            time--;
          }
        }, 1000);
      },

      //获取手机验证码
      async getPhoneCodeClick() {
        if (proxy.validatePhone) {
          // 如输入手机号正确并查找手机号成功
          await requestApi(
            () => {
              return getPhoneCodeApi(proxy.PhoneValue);
            },
            (res) => {
              if (res.code == 200) {
                data.PhoneCode = res.data;
              }
            }
          );
        }
      },

      // 修改密码
      async updatePasswordClick() {
        await requestApi(
          () => {
            return updatePasswordApi({
              userId: data.userId,
              password: encryptUtil.encrypt(data.NewPassword),
            });
          },
          (res) => {
            if (res.code == 200) {
              active.value = 2;
            }
          }
        );
      },
      // 完成
      completeClick() {
        active.value = 0;
        data.PhoneValue=""
        data.verifyCode=""
        data.NewPassword=""
        data.ConfNewPassword=""
      },
    };
    return {
      router,
      active,
      next,
      ...toRefs(data),
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.page-index-wide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -10;
  zoom: 1;
  background-size: cover !important;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  height: 100vh;
  overflow: hidden;
  background: url("~@/assets/login/background.png") fixed no-repeat;
}
.head {
  margin: 24px;
}
.butClass {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  width: 65px;
  height: 26px;
  background: #2065eb;
  border: 1px solid #2065eb;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
}
.divClass {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.inputPhone {
  width: 300px;
  margin: 35px 160px;
}
.inputPhone01 {
  width: 300px;
  margin: 35px 160px;
  /deep/.el-input {
    width: 55%;
  }
}
.nextClass {
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #3773f5;
  font-size: 18px;
  color: #ffffff;
  margin: 35px 160px;
  opacity: 1;
  border-radius: 6px;
}
.fonttxe {
  font-size: 18px;
  line-height: 28px;
}
.TipsClass {
  font-size: 14px;
  line-height: 18px;
  color: rgb(218, 10, 10);
}
</style>
