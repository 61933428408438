import { axios } from '@/utils/request'

const api = {
    selectPhone: '/company/companyUser/selectPhone', // 查找手机号
    phoneCode: '/company/companyUser/phoneCode/', // 获取手机验证码
    updatePassword:'/company/companyUser/updatePassword',// 修改密码
}
export default api

/** 查找手机号 （对象传参）*/
export const selcetPhoneApi = (params) => {
    return axios({
        url: api.selectPhone,
        method: 'post',
        data: params
    })
}

// 获取手机验证码（拼接传参）
export const getPhoneCodeApi = (params) => {
    return axios({
        url: api.phoneCode + params,
        method: 'post',

    })
}

/** 修改密码 （对象传参）*/
export const updatePasswordApi = (params) => {
    return axios({
        url: api.updatePassword,
        method: 'post',
        data: params
    })
}
